<template>
  <div>
    <div class="d-flex justify-content-end align-items-center">
      <eralp-pagination
        :page="page"
        :pages="pages"
        @change="(e) => SET_PAGINATION(e, true)"
      />
    </div>
    <CardTable
      :allItemsCount="allItemsCount"
      :loading="loading"
      @export:pdf="GET_REPORT_DETAIL_THEN_EXPORT_PDF"
      :listOfReports="listOfReports"
    />
    <div class="d-flex justify-content-end align-items-center mb-5">
      <eralp-pagination
        :page="page"
        :pages="pages"
        @change="(e) => SET_PAGINATION(e, true)"
      />
    </div>
  </div>
</template>

<script>
import { dataFormatterNaApiWithTime } from "../../plugins/date-formatter";
import ExportPdf from "../Exports/exportPDF";
import ExportExcel from "../Exports/ExportExcell.vue";
import CardTable from "../ui/table/cardTableReports.vue";
export default {
  mixins: [ExportPdf, ExportExcel],
  components: {
    CardTable,
  },
  data() {
    return {
      excelModalLoading: false,
      excelModal: false,
      loading: false,
      page: 1,
      limit: 10,
      listOfReports: [],
      allItemsCount: 0,
      headers: [{ label: "Date/Type" }, { label: "Company/Product" }],
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.allItemsCount / 10);
    },
  },
  props: {
    product: {
      default: () => {},
    },
    company: {
      default: () => {
        return {
          id: -2,
        };
      },
    },
  },
  methods: {
    SET_DETAIL_THEN_PUSH(row) {
      this.$router.push(`/report-detail/${row.id}`);
    },
    SET_DATE(date) {
      return dataFormatterNaApiWithTime(date);
    },
    async GET_REPORT_LIST_WITH_FILTER(isExcell = 0, isCompleted = 1) {
      if (isExcell === 1) {
        this.excelModalLoading = true;
        this.excelModal = true;
      }
      this.loading = true;
      let url = `CompanyReports/ByFilter?isExcel=0&offset=${
        isCompleted === 0 ? 1 : this.page
      }&limit=${this.limit}&isCompleted=${isCompleted}`;
      if (isExcell === 1)
        url = `CompanyReports/ByFilter?isExcel=1&isCompleted=1`;
      const response = await this.$api.post(url, {
        companyId: this.company.id == -2 ? 0 : this.company.id,
        companyProductId: this.product.companyProduct.id,
        createdAt: "",
        brandId: 0,
        reportType: 0,
        filterAnswers: [],
        sortBy: "date-desc",
      });
      if (response.result === "OK" && response.message === "OK") {
        if (isExcell === 1) {
          this.SET_EXCEL_FIELDS_DATA_FOR_EXPORT(response.data);
          this.loading = false;
        } else if (isCompleted === 1) {
          this.listOfReports = response.data.items;
          this.allItemsCount = response.data.count;
          this.loading = false;
        }
      } else {
        this.listOfReports = [];
        this.loading = false;
      }
    },
  },
  mounted() {
    this.GET_REPORT_LIST_WITH_FILTER();
  },
};
</script>

<style>
</style>