<template>
  <div class="mt-4">
    <div class="d-flex justify-content-end align-items-center mb-5">
      <eralp-pagination
        :page="page"
        :pages="pages"
        @change="(e) => $emit('change', e, true)"
      />
    </div>
    <CardTableHeader :headers="headers"></CardTableHeader>
    <CardTableBody
      :allItemsCount="allItemsCount"
      :items="listOfProducts"
      :loading="loading"
      :page="page"
      :pages="pages"
    >
      <div
        class="
          card
          row
          p-3
          d-flex
          flex-row
          justify-content-sm-end justify-content-xl-start
        "
        v-for="(row, i) in listOfProducts"
        :key="i"
      >
        <span
          class="position-absolute bg-primary px-2 text-white"
          style="top: 0; left: 0px; border-radius: 10px 0 3px 0"
          >{{ allItemsCount - ((page - 1) * 10 + i) }}.</span
        >
        <div class="col-12 col-lg-5">
          <div
            @click="SET_DETAIL_THEN_PUSH(row, 'product-update')"
            style="min-width: auto"
          >
            <strong style="font-size: 16px" class="text-primary my-2"
              >{{ row.companyProduct.serialNo }} /
              {{ row.companyProduct.productLocation }}
            </strong>
            <div>
              <small>
                <i class="my-2">
                  {{ row.brand.name }} > {{ row.model }}
                </i></small
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3 mt-2 mt-lg-0">
          <div>
            <router-link :to="`/companies/${row.companyId}`">
              <strong class="d-lg-none">Company:</strong> {{ row.companyName }}
            </router-link>
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-4 mt-lg-0">
          <td>
            <button
              @click="SET_DETAIL_THEN_PUSH(row, 'product-reports')"
              class="btn btn-info btn-sm m-2 m-lg-0 mr-lg-2"
            >
              Reports of Product
            </button>
            <button
              @click="SET_DETAIL_THEN_PUSH(row, 'product-history')"
              class="btn btn-danger btn-sm m-2 m-lg-0 mr-lg-2"
            >
              History
            </button>
            <button
              @click="SET_DETAIL_THEN_PUSH(row, 'product-update')"
              class="btn btn-warning btn-sm m-2 m-lg-0 mr-lg-2"
            >
              Update
            </button>
          </td>
        </div>
      </div>
    </CardTableBody>
    <div class="d-flex justify-content-end align-items-center mb-5">
      <eralp-pagination
        :page="page"
        :pages="pages"
        @change="(e) => $emit('change', e, true)"
      />
    </div>
    <Modal
      :title="modalTitle"
      :okText="modalOkButton"
      :hideFooter="!modalOkButton"
      :width="activeComponent !== 'product-update' ? 'lg' : ''"
      v-model="productDetailModal"
    >
      <div class="card-body px-0 px-lg-4">
        <component
          @update:success="
            productDetailModal = false;
            $emit('refresh');
          "
          :key="activeProductDetail.companyProduct.id"
          :product="activeProductDetail"
          :is="activeComponent"
        ></component>
      </div>
    </Modal>
  </div>
</template>

<script>
import { dataFormatterNaApiWithTime } from "../../../plugins/date-formatter";
import CardTableBody from "./cardTableBody.vue";
import CardTableHeader from "./cardTableHeader.vue";
import productHistory from "../../productDetail/productHistory.vue";
import productUpdate from "../../productDetail/productUpdate.vue";
import productReports from "../../productDetail/reportsOfProduct.vue";
import Modal from "../modal.vue";
export default {
  data() {
    return {
      activeComponent: "",
      productDetailModal: false,
      activeProductDetail: { status: false, companyProduct: { id: -1 } },
    };
  },
  computed: {
    modalTitle() {
      if (this.activeComponent === "product-history") return "Product History";
      else if (this.activeComponent === "product-update")
        return "Product Update";
      else if (this.activeComponent === "product-reports")
        return "Product Reports";
      else return "";
    },
    modalOkButton() {
      if (this.activeComponent === "product-history") return false;
      else if (this.activeComponent === "product-update") return false;
      else if (this.activeComponent === "product-reports") return false;
      else return false;
    },
  },
  props: {
    listOfProducts: {
      default: () => [],
    },
    page: {
      default: 1,
    },
    pages: {
      default: 0,
    },
    loading: {
      default: true,
    },
    allItemsCount: {
      default: 0,
    },
    headers: {
      default: () => [],
    },
  },
  methods: {
    SET_DETAIL_THEN_PUSH(row, comp) {
      console.log(row);
      this.activeComponent = comp;
      this.activeProductDetail = { ...row, status: true };
      this.productDetailModal = true;
    },
    SET_DATE(date) {
      return dataFormatterNaApiWithTime(date);
    },
  },
  components: {
    CardTableBody,
    CardTableHeader,
    Modal,
    productHistory,
    productReports,
    productUpdate,
  },
};
</script>

<style>
</style>