<template>
  <div>
    <div
      id="alertbox"
      v-if="alertBox.status"
      :class="`alert alert-${alertBox.variant}`"
    >
      {{ alertBox.message }}
    </div>
    <div class="my-4">
      <div class="card-title">Product Information</div>
    </div>
    <div class="text-right">
      <button
        v-if="updateSerialNo"
        @click="
          productData.companyProduct.serialNo = oldSerialNo;
          updateSerialNo = false;
        "
        class="btn btn-danger btn-xs"
      >
        Undo
      </button>
      <button
        v-else
        @click="
          oldSerialNo = productData.companyProduct.serialNo;
          updateSerialNo = true;
        "
        class="btn btn-warning"
      >
        Edit Serial No.
      </button>
    </div>
    <div class="form-group">
      <label for="email">Serial No</label>
      <span class="ml-2 badge badge-danger">required</span>
      <input
        class="form-control"
        id="email"
        :disabled="!updateSerialNo"
        maxlength="50"
        v-model="productData.companyProduct.serialNo"
        required
        aria-describedby="emailHelp"
        placeholder="Enter Serial No"
      />
    </div>
    <div>
      <i> {{ product.brand.name }} > {{ product.model }} </i>
    </div>
    <div id="alertbox" v-if="updateSerialNo" :class="`alert alert-info mt-4`">
      If you change the serial number,it will be changed in all data.
    </div>
    <hr />
    <div v-if="!updateSerialNo">
      <div class="my-4">
        <label for="">Company Name</label>
        <v-select
          @search="GET_COMPANIES_LIST"
          label="name"
          class="style-chooser"
          @option:selected="SET_SELECTED_COMPANY"
          :clearable="false"
          :value="
            activeCompany.id === -1 || activeCompany.id === -2
              ? null
              : activeCompany
          "
          placeholder="Search company name"
          :options="companies"
        ></v-select>
      </div>
      <div class="form-group">
        <label for="email">Machine Location</label>
        <span class="ml-2 badge badge-danger">required</span>
        <input
          class="form-control"
          id="email"
          maxlength="50"
          v-model="productData.companyProduct.productLocation"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Machine Location"
        />
      </div>
    </div>
    <div class="mt-4 text-right d-flex justify-content-end">
      <button
        :disabled="loading"
        @click="SET_MIDDLEWARE_FOR_UPDATE"
        class="btn btn-primary d-flex"
      >
        <span
          v-if="loading"
          class="spinner-border spinner-border-sm mr-2"
          role="status"
          aria-hidden="true"
        ></span>
        Save it.
      </button>
    </div>
  </div>
</template>

<script>
import CompanyActions from "../createReport/chooseCompany/companyActions";
export default {
  mixins: [CompanyActions],
  props: {
    product: {
      default: () => {},
    },
  },
  data() {
    return {
      productData: {
        companyProduct: {
          serialNo: "",
        },
      },
      updateSerialNo: false,
      oldSerialNo: "",
      loading: false,
      alertBox: {
        status: false,
      },
    };
  },
  watch: {
    product(data) {
      this.productData = data;
    },
  },
  methods: {
    SET_MIDDLEWARE_FOR_UPDATE() {
      if (this.updateSerialNo) this.SEND_UPDATE_SERIALNO();
      else this.SEND_UPDATE_COMPANY_PRODUCT();
    },
    async SEND_UPDATE_SERIALNO() {
      if (this.productData.companyProduct.serialNo === "") return;
      this.loading = true;
      const response = await this.$api.put(
        `CompanyProducts/${this.productData.companyProduct.id}`,
        {
          serialNo: this.productData.companyProduct.serialNo,
        }
      );
      if (response.result === "OK" && response.message === "OK") {
        this.alertBox = {
          status: true,
          message: "Serial no. has changed with succesfully",
          variant: "success",
        };
        setTimeout(() => {
          this.$emit("update:success");
        }, 2500);
      } else
        this.alertBox = {
          status: true,
          message:
            "Serial no. has not changed with succesfully. Please Try Again.",
          variant: "danger",
        };
      this.loading = false;
    },
    async SEND_UPDATE_COMPANY_PRODUCT() {
      if (this.productData.companyProduct.productLocation === "") return;
      this.loading = true;
      const response = await this.$api.put(
        `CompanyProducts/Relocate/${this.productData.companyProduct.id}`,
        {
          productLocation: this.productData.companyProduct.productLocation,
          companyId: this.activeCompany.id,
        }
      );
      if (response.result === "OK" && response.message === "OK") {
        this.alertBox = {
          status: true,
          message: "Serial no. has changed with succesfully",
          variant: "success",
        };
        setTimeout(() => {
          this.$emit("update:success");
        }, 2500);
      } else
        this.alertBox = {
          status: true,
          message:
            "Serial no. has not changed with succesfully. Please Try Again.",
          variant: "danger",
        };
      this.loading = false;
    },
  },
  async mounted() {
    this.productData = {
      ...this.product,
      companyProduct: { ...this.product.companyProduct },
    };
    this.GET_COMPANIES_LIST("", () => false);

    this.activeCompany = {
      id: this.product.companyId,
      name: this.product.companyName,
    };
  },
};
</script>

<style>
</style>