<template>
  <div>
    <div class="d-flex justify-content-end align-items-center">
      <eralp-pagination
        :page="page"
        :pages="pages"
        @change="(e) => SET_PAGINATION(e, true)"
      />
    </div>
    <cardTableHeader :headers="headers" />
    <cardTableBody
      :allItemsCount="historyList.length"
      :items="historyList"
      :loading="loading"
      :page="1"
      :pages="1"
    >
      <div
        class="
          card
          row
          p-3
          d-flex
          flex-row
          justify-content-sm-end justify-content-xl-start
        "
        v-for="(item, i) in historyList"
        :key="i"
      >
        <div class="col-12 col-lg-4">
          <strong class="d-lg-none">company:</strong>
          {{ item.companyName }}
        </div>
        <div class="col-12 col-lg-4 mt-2 mt-lg-0">
          <strong style="font-size: 16px" class="text-primary my-2"
            >{{ item.companyProduct.serialNo }} /
            {{ item.companyProduct.productLocation }}
          </strong>
          <div>
            <i class="my-2"> {{ item.brand.name }} > {{ item.model }} </i>
          </div>
        </div>
        <div class="col-12 col-lg-3 mt-2 mt-lg-0">
          <button
            class="btn btn-block"
            :class="{
              'btn-danger': item.isActive === 0,
              'btn-primary': item.isActive === 1,
            }"
          >
            {{ SET_DATE(item.createdAt) }}
          </button>
        </div>
      </div>
    </cardTableBody>
    <div class="d-flex justify-content-end align-items-center">
      <eralp-pagination
        :page="page"
        :pages="pages"
        @change="(e) => SET_PAGINATION(e, true)"
      />
    </div>
  </div>
</template>

<script>
import { dataFormatterNaApiWithTime } from "../../plugins/date-formatter";
import cardTableHeader from "../ui/table/cardTableHeader.vue";
import cardTableBody from "../ui/table/cardTableBody.vue";
export default {
  components: {
    cardTableHeader,
    cardTableBody,
  },
  props: {
    product: {
      default: () => {},
    },
  },
  data() {
    return {
      page: 1,
      count: 10,
      loading: false,
      historyList: [],
      headers: [
        { label: "Company", col: 12, md: 12, lg: 4, xl: 4 },
        { label: "Product", col: 12, md: 12, lg: 4, xl: 4 },
        { label: "Created At", col: 12, md: 12, lg: 3, xl: 3 },
      ],
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.count / 10);
    },
  },
  methods: {
    SET_DATE(date) {
      return dataFormatterNaApiWithTime(date);
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.GET_PRODUCT_HISTORY_LOG();
    },
    async GET_PRODUCT_HISTORY_LOG() {
      this.loading = true;
      const response = await this.$api.get(
        `CompanyProducts/Logs?serialNo=${this.product.companyProduct.serialNo}&offset=${this.page}&limit=10`
      );
      if (response.result === "OK" && response.message === "OK") {
        this.historyList = response.data;
      } else this.historyList = [];
      this.loading = false;
    },
  },
  mounted() {
    this.GET_PRODUCT_HISTORY_LOG();
  },
};
</script>

<style>
</style>